import globalApi from '@/request/globalApi/globalApi'
import axios from 'axios'

const END_POINT = '/translation'

export const fetchTranslationsRequest = (source, queryParams) => globalApi.get(`${END_POINT}-${source}`, { params: queryParams })

export const fetchApiTranslationsRequest = queryParams => globalApi.get(`${END_POINT}-api`, { params: queryParams })

// TODO: create instance for download
const token = `Bearer ${localStorage.getItem('token')}` || ''
const selectedOrganizationId = localStorage.getItem('selectedOrganization')
  && JSON.parse(localStorage.getItem('selectedOrganization')).id
const lang = localStorage.getItem('lang')
export const downloadTranslationCSV = () => {
  const token = `Bearer ${localStorage.getItem('token')}` || ''
  const selectedOrganizationId = localStorage.getItem('selectedOrganization')
    && JSON.parse(localStorage.getItem('selectedOrganization')).id
  const lang = localStorage.getItem('lang')

  return axios.get(`${process.env.VUE_APP_API_BASE_URL}api/export/translations`, {
    headers: { Accept: 'text/csv', 'Content-Type': 'text/csv', Authorization: token, selectedOrganizationId, lang },
  }).then(response => {
    const blob = new Blob(
      [response.data],
      { type: 'text/csv' },
    )
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = 'export.csv' // TODO dynamic name when instance created
    link.click()
  })
}

export const uploadTranslationCSV = csvFile => globalApi.post(`${process.env.VUE_APP_API_BASE_URL}api/import/translations`, csvFile, {
  headers: { 'Content-Type': 'multipart/form-data' },
})

// TODO single responsability : remove transforme data
export const patchTranslationForManyLocalesRequest = data => {
  const dataToSend = {
    key: data.key,
    localeTranslations: Object.keys(data.translations).map(translation => ({
      locale: translation,
      translation: data.translations[translation],

    })),
  }
  return globalApi.patch(`api${END_POINT}`, dataToSend)
}
